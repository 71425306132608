import { TribeService } from './../_services/tribe.service';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { AuthenticationService } from '../_services';
import { MatDialog } from '@angular/material/dialog';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
const TOKEN_HEADER_KEY = 'Authorization';
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(private router: Router, private tribeService: TribeService, private dialog: MatDialog,
      private authService: AuthenticationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): any {
      // add auth header with jwt if user is logged in and request is to api url
      // const currentUser = this.authenticationService.currentUserValue;
      let token = '',token1='';
    if(this.tribeService.hasToken()!=false){
       token = localStorage.getItem("accesst")?.toString();
    }


    let authReq = request;
    if (token != null && token != "") {
      if (!request.headers.get("Authorization")) {
        authReq = this.addToken(request, token);
      }
    } else {
      localStorage.removeItem("cUser");
      localStorage.removeItem("role");
      localStorage.clear();
      this.router.navigate(["/login"]);
      this.dialog.closeAll();
    }
      return next.handle(authReq).pipe(catchError(error => {
          const re = '/auth/realms';
          const aare = '/admin/auth';
          const relog = '/logout';
          if (error instanceof HttpErrorResponse && request.url.search(re) === -1 && request.url.search(aare) === -1 && request.url.search(relog) === -1 && error.status === 401) {

            return this.handle401Error(authReq, next);
          } else if (request.url.search(relog) === -1 && error.status != 200) {

              return throwError(error);
          }
          return throwError(error);
      }));
  }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {

        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);
            const token = localStorage.getItem('refresh_token');
            if (token) {
                return this.tribeService.getRefreshToken(token).pipe(
                    switchMap((token: any) => {
                        this.isRefreshing = false;
                        localStorage.setItem('accesst', token['access_token']);
                        localStorage.setItem('refresh_token', token['refresh_token']);
                        this.refreshTokenSubject.next(token.access_token);
                        return next.handle(this.addToken(request, token.access_token));
                    }), catchError((err) => {
                        if (err instanceof HttpErrorResponse && err.status == 400) {
                            if (err.error.description == 'Token is not active') {
                                localStorage.clear();
                            }
                        }
                        this.isRefreshing = false;
                        this.authService.logout();
                        //localStorage.clear();
                        //this.router.navigate(['/login']);
                        return throwError(err);
                    })
                );
            } else {
                this.isRefreshing = false;
                //localStorage.clear();
                // this.authService.logout();
                this.router.navigate(['/login']);
            }
        }

        return this.refreshTokenSubject.pipe(
            filter(token => token !== null),
            take(1),
            switchMap((token) => next.handle(this.addToken(request, token)))
        );
    }

    addToken(req: HttpRequest<any>, token: any): HttpRequest<any> {
        if (token) {
            return req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });
        }
    }
}
