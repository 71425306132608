import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { config } from '../../config';
import { User } from '../_models';
import { TribeService } from '../_services/tribe.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    public loggedUser: User;
    roleAs: string;
    constructor(private http: HttpClient, public tribeService: TribeService, private router: Router) {
        const currentUserencoded =this.tribeService.getLocal('cUser');        // sessionStorage encoded current Usere
        if (currentUserencoded) {
            var currentUser = JSON.parse(atob(currentUserencoded));      // sessionStorage decoded current Usere
        }
        this.currentUserSubject = new BehaviorSubject<User>(currentUser);
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }
    login(username: string, password: string, token: string) {
        let user: User;
        this.loggedUser = {
            id: 1,
            username: username,
            firstName: 'fName',
            lastName: 'Last name',
            password: password,
            token: token
        };
       this.tribeService.setLocal('cUser', btoa(JSON.stringify(this.loggedUser)));   // Store login user in session encoded format
        this.currentUserSubject.next(<User>this.loggedUser);
    }

    login1(username: string, password: string) {
        return this.http.post<any>(`${config.apiUrl}/users/authenticate`, { username, password })
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                   this.tribeService.setLocal('cUser', JSON.stringify(user));
                    this.currentUserSubject.next(user);
                }
                return user;
            }));
    }

    getRole() {
        this.roleAs = this.tribeService.getLocal('access_log');//localStorage.getItem('access_log');
        return this.roleAs;
    }
    public envName = environment.env;
      private baseURL: string = environment.BASE_URL; //qa
    logoutV1(unitType: string) {
        // var httpOptions = { headers: new HttpHeaders({ Authorization: "Bearer" }) };
        return this.http.delete(`${this.baseURL}${unitType}/vehicle-user/logout`,
            {observe: 'response' }).pipe(catchError(err => of(err)));
    }
    logout() {
        this.logoutV1('pv').subscribe(a => {

            if (a.status == 200) {
                sessionStorage.clear();
                localStorage.clear();
                this.tribeService.tribeURL = '';
                this.currentUserSubject.next(null);
                this.router.navigate(['/login']);
            }else{
                sessionStorage.clear();
                localStorage.clear();
                  this.tribeService.tribeURL = '';
                  this.router.navigate(['/login']);
                  this.currentUserSubject.next(null);
            }
        });
    }
}
