import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../_services';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  currentUser: string;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
  }

  ngOnInit() {
    this.currentUser = localStorage.getItem('proname');

    let userrole = localStorage.getItem('access_log');
    if (!userrole) {
      this.router.navigate(['/login']);
      return false;
    }
  }

  logout() {
    this.authenticationService.logout();
    // this.router.navigate(['/login']);
  }

  dashbord() {
    let cUser = localStorage.getItem('access_log');
    if (cUser === 'cvp_ev_tribe_admin' || cUser === 'cvp_pv_tribe_admin') {
      this.router.navigate(['/tribe-list']);
    } else if (cUser === 'cvp_ev_back_office' || cUser === 'cvp_back_office') {
      //this.router.navigate(['/custom-notification']);
      this.router.navigate(['/car-immobilizer']);
    } else if (cUser === 'cvp_ev_productline_admin' || cUser === 'cvp_pv_productline_admin') {
      this.router.navigate(['/master-feature-list']);
    }
  }
}
