<!-- Sign in Wrapper -->

<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "ball-spin-clockwise-fade-rotating" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner>
<div class="sign-in-wrapper container-fluid flex-column">
  <div class="sign-in-img-bg">
    Hello
  </div>
  <div class="card sign-in-card">
    <img src="./assets/image/tata-logo.png" class="mb-5 login-logo">
    <!-- Sign in form -->
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <mat-form-field class="d-block" appearance="outline" style="font-size:1rem">
        <mat-label>User name</mat-label>
        <input formControlName="username" type="text" pattern='^(?!.*<[^>]+>).*' matInput placeholder="" autocomplete="off">
        <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
          <div *ngIf="f.username.errors.required">Username is required</div>
          <div *ngIf="f.username.errors.pattern">No HTML tags are allowed!</div>
        </div>
      </mat-form-field>

      <mat-form-field class="d-block pb-4" appearance="outline" style="font-size:1rem">
        <mat-label>Password</mat-label>
        <input formControlName="password" type="password" matInput placeholder="" autocomplete="off">
        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
          <div *ngIf="f.password.errors.required">Password is required</div>
        </div>
        <mat-hint class="mat-error mt-2" *ngIf="loginerror">Invalid user credentials</mat-hint>
      </mat-form-field>

      <div class="button-container d-flex justify-content-center">
        <button mat-raised-button color="primary" [disabled]='!loginForm.valid' class="btn btn-primary">Login</button>
      </div>
      <img *ngIf="loading" class="pl-2 loader"
        src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
      <div *ngIf="error" class="alert alert-danger">{{error}}</div>
    </form>
  </div>
</div>
