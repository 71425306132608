export const environmentkeys = {
  env: 'prod',
  prod: {
    AuthToken: 'Y3ZwLXVzZXI6RW9uQllzIXF1YSZ6WVE1aHU=',
    BASE_URL: "https://cvp-api.pv.tatamotors/",
    AUTH_BASE_URL: "https://cvp-api.pv.tatamotors",
    BASE_IMAGES_URL: "https://cvp-content.pv.tatamotors/",
    BASETOKEN: "Y3ZwLXVzZXI6RW9uQllzIXF1YSZ6WVE1aHU=",
    CLIENT_ID: "cvp_layer4_api",
    CLIENT_SECRET: "7de4353d-0287-44cc-bfce-85079cc0a16f",
    grant: "password",
    imageCarQueryString:"vehicle"
  }
};
