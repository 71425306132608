import { Component, OnInit } from '@angular/core';
import { User } from '../../../_models';
import { UserService, AuthenticationService } from '../../../_services';
import { MatDialog } from '@angular/material/dialog';
import { TribeService } from '../../../_services/tribe.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  users: User[] = [];
  all_car: string = this.tribeService.getLocal('access_log');//localStorage.getItem('access_log');


  constructor(
    private userService: UserService,
    public dialog: MatDialog,
    public tribeService: TribeService,
  ) {
  }
  ngOnInit() {
  //  this.getTribes();
  }
  getTribes() {

    this.tribeService.getTribes()
      .subscribe(
        tribes => this.tribeService.tribesCount = tribes.length,
      ),error => {
        //
      }
  }
}
