<div class="wrapper-with-bg">
      <div class="ev-dashboard-wrapper container">
            <header>
                  <app-header></app-header>
            </header>
            <div class="d-flex justify-content-start">
                  <div class="navigation">
                        <ul class="navigation-list">
                              <ng-container *ngIf="all_car == 'cvp_pv_tribe_admin'">
                                    <li><a routerLink="/tribe-list" routerLinkActive="active" class=""><img class="icon"
                                                      src="./assets/image/tribe.svg" class="pr-2"><span>Tribe
                                                      management</span></a></li>
                                    <li><a routerLink="/car-images" routerLinkActive="active" class=""><i
                                                      class="material-icons">photo_library</i><span>Car
                                                      images</span></a></li>
                                    <li><a routerLink="/app-version" routerLinkActive="active" class=""><i
                                                      class="material-icons">settings</i><span>App Version</span></a>
                                    </li>
                                    <!-- <li><a routerLink="/car-immobilizer" routerLinkActive="active" class=""><i class="material-icons">track_changes</i><span>Car Immobilizer</span></a></li>    -->
                                    <!-- <li class="create-section"><a routerLink="/create-tribe" routerLinkActive="active" class=""><i class="material-icons">my_library_add</i><span>Create tribe</span></a></li> -->
                              </ng-container>
                              <ng-container *ngIf="all_car == 'cvp_back_office'">
                                    <!-- <ng-container *ngIf="tribeService.envName != 'prod'"> -->
                                    <li><a routerLink="/car-immobilizer" routerLinkActive="active" class=""><i
                                                      class="material-icons">track_changes</i><span>Car
                                                      Immobilizer</span></a></li>
                                    <li><a routerLink="/custom-notification" routerLinkActive="active" class=""><i
                                                      class="material-icons">notification_important</i><span>Custom
                                                      Notification</span></a></li>
                                    <li><a routerLink="/feedback" routerLinkActive="active" class=""><i
                                                      class="material-icons">feedback</i><span>Feedback</span></a></li>
                                    <li><a routerLink="/subscribers-list" routerLinkActive="active" class=""><i
                                                      class="material-icons">perm_media</i><span>Subscribers
                                                      List</span></a></li>
                                    <li><a routerLink="/subscription-packages" routerLinkActive="active" class=""><img
                                                      class="icon" src="./assets/image/sub-pack.svg"><span>Subscription
                                                      Packages</span></a>
                                    </li>
                                    <!-- <li><a routerLink="/valet-mode" routerLinkActive="active" class=""><i class="material-icons">airport_shuttle</i><span>Valet mode management</span></a></li> -->
                                    <!-- </ng-container> -->
                              </ng-container>
                              <ng-container *ngIf="all_car == 'cvp_pv_support_team'">
                                    <li><a routerLink="/feedback" routerLinkActive="active" class=""><i
                                                      class="material-icons">feedback</i><span>Feedback</span></a></li>
                                    <li><a routerLink="/car-immobilizer" routerLinkActive="active" class=""><i
                                                      class="material-icons">track_changes</i><span>Car
                                                      Immobilizer</span></a></li>
                              </ng-container>
                              <ng-container *ngIf="all_car == 'cvp_pv_productline_admin'">
                                    <li><a routerLink="/master-feature-list" routerLinkActive="active" class=""><i
                                                      class="material-icons">article</i><span>Feature List</span></a>
                                    </li>
                                    <li><a routerLink="/vehicle-profile" routerLinkActive="active" class=""><i
                                                      class="material-icons">directions_car</i><span>Vehicle
                                                      Profile</span></a></li>
                              </ng-container>
                              <ng-container
                                    *ngIf="all_car == 'cvp_pv_subscriber_team'">
                                    <li><a routerLink="/subscribers-list" routerLinkActive="active" class=""><i
                                                      class="material-icons">perm_media</i><span>Subscribers
                                                      List</span></a></li>
                                    <li><a routerLink="/subscription-packages" routerLinkActive="active" class=""><img
                                                      class="icon" src="./assets/image/sub-pack.svg"><span>Subscription
                                                      Packages</span></a></li>
                              </ng-container>
                              <ng-container *ngIf="all_car == 'cvp_pv_immo_team'">
                                    <li><a routerLink="/car-immobilizer" routerLinkActive="active" class=""><i
                                                      class="material-icons">track_changes</i><span>Car
                                                      Immobilizer</span></a></li>
                              </ng-container>
                        </ul>
                  </div>
                  <div class="widget-wrapper">
                        <!-- <ng-scrollbar autoHeightDisabled="false">
 -->
                        <router-outlet></router-outlet>
                        <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "ball-spin-clockwise-fade-rotating" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner>
                        <!-- </ng-scrollbar> -->
                  </div>
            </div>
      </div>
</div>
