<div class="d-flex align-items-center justify-content-between head-section">
    <div class="logo-panel d-flex justify-content-start align-items-center" (click)="dashbord()">
        <img src="./assets/image/tata-logo.png" height="100%" class="login-logo" >
    </div>
    <button mat-button [matMenuTriggerFor]="menu" class="ml-auto">
        <span class="img-container">
            <img src="./assets/image/profile.png"/>
        </span>
    </button>
    <mat-menu #menu="matMenu" class="dark-mat-menu profile-mat-menu" xPosition="before">
        <div class="d-flex align-items-center logged-in-usr-header">
            <span class="img-container">
                <img src="./assets/image/profile.png"/>
            </span>
            <span>
                <h4>User name</h4>
                <p *ngIf="currentUser !== 'undefined'">{{currentUser}}</p>
            </span>
        </div>
        <div class="navigation">
                <ul class="navigation-list">                  
                    <li><a (click)="logout()" class=""><i class="material-icons">logout</i><span>Logout</span></a></li>
                </ul>
            </div>
    </mat-menu>
</div>
    
