import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../../_services';
import { TribeService } from '../../../_services/tribe.service';
import { User } from '../../../_models/user';
import { decodeJwt as jwt_decode } from 'jose';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
    selector: 'app-sign-in',
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
    loginForm: FormGroup;
    forgotPasswordForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    error = '';
    user: User;
    currentuser: string;
    loginerror: boolean = false;
    constructor(private formBuilder: FormBuilder, private route: ActivatedRoute,
        private router: Router, private authenticationService: AuthenticationService,
         public tribeService: TribeService, private dialog: MatDialog,private spinnerService: NgxSpinnerService) {
            debugger;
          }

    ngOnInit() {
        debugger;
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });
        this.forgotPasswordForm = this.formBuilder.group({
            email: ['', Validators.required]
        });
        debugger;
        let currentUser = this.authenticationService.currentUserValue;
        if (currentUser) {
            this.redirectonSuccess();
            // logged in so return true
            return true;
        } else {
            // reset login status
            let token =this.tribeService.getLocal('accesst');
            if (token !== 'undefined' && token !== undefined && token !== null) {

                this.authenticationService.logout();
               this.tribeService.setLocal('accesst', '');
            }
        }
        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }
    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    onSubmit() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }
        // debugger;
        let formData = { username: this.f.username.value, password: this.f.password.value };
        this.spinnerService.show();
        debugger;
        this.tribeService.getAuthTokenV1(formData).subscribe((res: HttpErrorResponse) => {

            if (res instanceof HttpErrorResponse && res.status == 401) {
                this.spinnerService.hide();
                this.loading = false;
                let { error, error_description } = res.error;
                this.error = error_description;//'Invalid Credentials !!!';
                setTimeout(() => {
                    this.error = '';
                }, 5000);
                return false;
            } else if (res instanceof HttpErrorResponse && res.status == 400) {
                this.loading = false;
                let { error, error_description } = res.error;
                this.error = res.error;//'Invalid Credentials !!!';
                this.spinnerService.hide();
                setTimeout(() => {
                    this.error = '';
                }, 5000);
                return false;
            } else if (res instanceof HttpErrorResponse && res.status != 200) {
                this.loading = false;
                let { error, error_description } = res.error;
                this.error = error_description;//'Invalid Credentials !!!';
                this.spinnerService.hide();
                setTimeout(() => {
                    this.error = '';
                }, 5000);
                return false;
            } else {

                let token: string = res['access_token'];
               this.tribeService.setLocal('accesst', token);
                let refreshToken: string = res['refresh_token'];
               this.tribeService.setLocal('refresh_token', refreshToken);
                let role = ''
                let unit = '';
                let decoded:any = jwt_decode(token);
                this.spinnerService.hide();
                role = decoded.resource_access.cvp_layer4_api.roles[0];   // getting the role
                if (role == "cvp_pv_tribe_admin") {
                    unit = 'pv';
                } else if (role == "cvp_back_office") {
                    unit = 'pv';
                } else if (role == "cvp_pv_productline_admin") {
                    unit = 'pv';
                } else if (role == "cvp_pv_support_team") {
                    unit = 'pv';
                } else if (role == "cvp_pv_subscriber_team") {
                    unit = 'pv';
                } else if (role == "cvp_pv_immo_team") {
                    unit = 'pv';
                } else {
                    this.loginerror = true;
                    return;
                }
                console.log("decoded",decoded);
                console.log("test",unit);
               this.tribeService.setLocal('proname', decoded?.preferred_username);
               this.tribeService.setLocal('access_log', role);
                this.tribeService.setLocal('access_log', role);
               this.tribeService.setLocal('trole', unit);  // add to the role in the session
                //localStorage.setItem('trole', 'pv');
                this.tribeService.authToken = token;
                if (localStorage.getItem('accesst') != '') {
                    this.authenticationService.login(this.f.username.value, this.f.password.value, token);
                    this.loading = true;
                    this.redirectonSuccess();
                } else {
                    this.error = 'Unauthorized user';
                    this.loading = false;
                    this.router.navigate(['/login']);
                    this.dialog.closeAll();
                }
                return true;
            }
        },
            (error) => {
              this.spinnerService.hide();
                this.loading = false;
                if (error instanceof HttpErrorResponse) {

                }
                this.error = error;//'Invalid Credentials !!!';
                setTimeout(() => {
                    this.error = '';
                }, 5000);
            }
        );
        return false;
        // this.tribeService.getTokenForUser(this.f.username.value, this.f.password.value).subscribe(res => {

        // }, error => {
        //     this.loginerror = true;
        // });
    }
    redirectonSuccess() {
        let cvpUserRole =this.tribeService.getLocal('access_log');
        console.log(cvpUserRole,'role');
        if (cvpUserRole == "cvp_back_office") {
            //this.router.navigate(['/custom-notification']);
            this.router.navigate(['/car-immobilizer']);
            return true;
        } else if (cvpUserRole == "cvp_pv_productline_admin") {
            this.router.navigate(['/master-feature-list']);
            return true;
        } else if (cvpUserRole == "cvp_pv_support_team") {
            //this.router.navigate(['/custom-notification']);
            this.router.navigate(['/feedback']);
            return true;
        } else if (cvpUserRole == "cvp_pv_subscriber_team") {
            this.router.navigate(['/subscribers-list']);
            return true;
        } else if (cvpUserRole == "cvp_pv_immo_team") {
            this.router.navigate(['/car-immobilizer']);
            return true;
        } else if (cvpUserRole == "cvp_pv_tribe_admin") {
            this.router.navigate(['/tribe-list']);
            return true;
        }
        this.router.navigate(['/login']);
        return false;
    }
}

